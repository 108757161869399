import React from 'react'

import './footer.css'
import { graphql, useStaticQuery } from 'gatsby'

const socialURLs = {
  twitter: 'https://twitter.com',
  github: 'https://github.com',
  facebook: 'https://facebook.com',
  medium: 'https://medium.com',
  linkedin: 'https://linkedin.com/',
  instagram: 'https://instagram.com'
}

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
        query MainFooterQuery {
            site {
                siteMetadata {
                    author
                    bio
                    social {
                        twitter
                        github
                        facebook
                        medium
                        linkedin
                        instagram
                    }
                }
            }
        }
    `,
  )

  const { author, social, bio } = site.siteMetadata

  return (
    <section id='footer'>
      <section className="copyright">Blog de {author}, Développeurs d'applications innovantes</section>
      <nav className="site-footer-nav">
        <a href="/">Derniers articles</a>

        {Object.keys(social).map(s => social[s] ?
          (<a rel="noopener" target="_blank" aria-label={`${s} profile`} href={`${socialURLs[s]}/${social[s]}`}>{s}</a>) : null
        )}

        <a rel="noopener" target="_blank" href={`https://playmoweb.com`}>Playmoweb</a>
      </nav>
    </section>
  )
}

export default Footer
