import React from 'react'
import Image from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'

import './toolbar.css'

const Toolbar = () => {
  const { avatar } = useStaticQuery(
    graphql`
        query AvatarQuery {
            avatar: file(absolutePath: { regex: "/avatar.png/" }) {
                childImageSharp {
                    fixed(width: 40, height: 40, quality: 90) {
                        base64
                        width
                        height
                        src
                        srcSet
                    }
                }
            }
        }
    `,
  )

  return (
    <div className="toolbar">
      <div className="container">
        <a href="/">
          <Image className="toolbarAvatar" fixed={avatar.childImageSharp.fixed} />
        </a>
        <Link className="hide-for-small ps-30 tag" to="/tags/Events">Évenements</Link>
        <Link className="hide-for-small tag" to="/tags/Android">Android</Link>
        <Link className="hide-for-small tag" to="/tags/Development">Développement</Link>
        <Link className="hide-for-small tag" to="/tags/Products">Produits</Link>
        <div className="verticalSeparator" />
        <a className="tag" href="https://www.playmoweb.com">Playmoweb.com</a>
      </div>
    </div>
  )
}
export default Toolbar
